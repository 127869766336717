import React, { useEffect, useState } from 'react'
import '../.././App.css';
import Swal from 'sweetalert2';


export default function Home() {

    let user = JSON.parse(localStorage.getItem('user_info'));
    const [profile_data, setData] = useState([]);

    const user_id = user['user_id'];
    const type = 'Student';
    const [photo, setphoto] = useState("");
    const [name, setname] = useState("");
    const [nickname, setnickname] = useState("");
    const [mobile, setmobile] = useState("");
    const [parmobile, setparmobile] = useState("");
    const [email, setemail] = useState("");
    const [gender, setgender] = useState("");
    const [bloodgroup, setbloodgroup] = useState("");
    const [username, setusername] = useState("");
    const [language, setlanguage] = useState("");
    const [tage, settage] = useState("");
    const [birthdate, setbirthdate] = useState("");
    const [city, setcity] = useState("");
    const [address, setaddress] = useState("");
    const [pincode, setpincode] = useState("");
    const [state, setstate] = useState("");
    const [classn, setclassn] = useState("");
    const [division, setdivision] = useState("");
    const [lasteducation, setlasteducation] = useState("");
    const [idealperson, setidealperson] = useState("");


    useEffect(async () => {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result); setname(result.name); setnickname(result.nick_name); setmobile(result.phone);setparmobile(result.phone); setemail(result.email); setgender(result.gender);
        setbloodgroup(result.blood_group); setusername(result.username); setlanguage(result.language); settage(result.age);
        setbirthdate(result.birth_date); setcity(result.city); setaddress(result.address); setpincode(result.pinocode); setstate(result.state);
        setclassn(result.class); setdivision(result.division);setidealperson(result.ideal_persons); setlasteducation(result.last_education); 
    }, [])

    async function UpdateProfile() {
        const form_data = new FormData();
        form_data.append('user_id', user_id);
        form_data.append('photo', photo);
        console.log(photo);
        if (photo === '') {
            Swal.fire('Image Not Found', 'Please select profile image', 'error')
        }
        else {

            let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/update_student_profile", { method: 'POST', body: form_data });
            result = await result.json();
            if (result === 1) {
                Swal.fire('Success!', 'Profile Image Successfully Updated', 'success')
                setphoto("");
                getData();
                window.location.href = "/edit-profile";
        }
            else { Swal.fire('Error!', 'Something Was Wrong, Please Try Again', 'error') }
        }
    }

    async function getData() {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);
    }

    async function UpdateData() {
        const form_data = new FormData();
        form_data.append('user_id', user_id); form_data.append('name', name); form_data.append('nickname', nickname);
        form_data.append('mobile', mobile);form_data.append('parmobile', parmobile); form_data.append('email', email); form_data.append('gender', gender);
        form_data.append('bloodgroup', bloodgroup); form_data.append('language', language); form_data.append('lasteducation', lasteducation);form_data.append('tage', tage);
        form_data.append('birthdate', birthdate); form_data.append('city', city); form_data.append('address', address);form_data.append('classn', classn);
        form_data.append('pincode', pincode); form_data.append('state', state); form_data.append('idealperson', idealperson);form_data.append('division', division);
        
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/update_students", { method: 'POST', body: form_data });
        result = await result.json();
        if (result === 1) { Swal.fire('Success!', 'Your Infromation Successfully Updated', 'success') }
        else { Swal.fire('Error!', 'Something Was Wrong, Please Try Again', 'error') }
        getData();
    }

    return (
        <div>
            <div className="content-page-box-area">
                <div className="my-profile-inner-box">
                    <div className="">
                        <div className="align-items-center">
                            <div>
                                <form className="account-setting-form">
                                    <h2>Profile Information</h2><hr />
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            {
                                                profile_data.other1 === '' ?
                                                    <img src="assets/images/dummy.png" alt="image" height="200" width="200" />
                                                    :
                                                    <img height="150" width="150" className="profile-user-img img-circle" src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} alt="profile " />
                                            }
                                        </div>
                                        <div className="col-lg-2 col-md-2">
                                            <label style={{ float: "left" }}>Update Profile</label>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <input type="file" className="form-control" onChange={(e) => setphoto(e.target.files[0])} />
                                        </div>

                                        <div className="col-lg-2 col-md-2">
                                            <button type="button" onClick={UpdateProfile} className="btn btn-primary form-control">Update Image</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}> Username</label>
                                                <input type="text" className="form-control" value={username} onChange={(e) => setusername(e.target.value)} readOnly/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}> Name</label>
                                                <input type="text" className="form-control" value={name} onChange={(e) => setname(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Nick Name</label>
                                                <input type="text" className="form-control" value={nickname} onChange={(e) => setnickname(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Mobile</label>
                                                <input type="number" className="form-control" value={mobile} onChange={(e) => setmobile(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Parent Mobile</label>
                                                <input type="number" className="form-control" value={parmobile} onChange={(e) => setparmobile(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}> Email</label>
                                                <input type="email" className="form-control" value={email} onChange={(e) => setemail(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Address</label>
                                                <input type="text" className="form-control" value={address} onChange={(e) => setaddress(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>City</label>
                                                <input type="text" className="form-control" value={city} onChange={(e) => setcity(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Pin Code</label>
                                                <input type="number" className="form-control" value={pincode} onChange={(e) => setpincode(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>State</label>
                                                <input type="text" className="form-control" value={state} onChange={(e) => setstate(e.target.value)}/>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Gender</label>
                                                <select className="form-select" value={gender} onChange={(e) => setgender(e.target.value)}>
                                                    <option value=''></option>
                                                    <option value='Male'>Male</option>
                                                    <option value='Female'>Female</option>
                                                    <option value='Other'>Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Blood Group</label>
                                                <select className="form-select" value={bloodgroup} onChange={(e) => setbloodgroup(e.target.value)}>
                                                    <option value=""></option>
                                                    <option value="A+">A+</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B+">B+</option>
                                                    <option value="B-">B-</option>
                                                    <option value="O+">O+</option>
                                                    <option value="O-">O-</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="AB-">AB-</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Class</label>
                                                <input type="text" className="form-control" value={classn} onChange={(e) => setclassn(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Division</label>
                                                <input type="text" className="form-control" value={division} onChange={(e) => setdivision(e.target.value)}/>
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Language</label>
                                                <input type="text" className="form-control" value={language} onChange={(e) => setlanguage(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Age</label>
                                                <input type="text" className="form-control"  value={tage} onChange={(e) => settage(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Birth Date</label>
                                                <input type="date" className="form-control" value={birthdate} onChange={(e) => setbirthdate(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Last Education</label>
                                                <input type="text" className="form-control" value={lasteducation} onChange={(e) => setlasteducation(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label style={{ float: "left" }}>Ideal Person</label>
                                                <input type="text" className="form-control" value={idealperson} onChange={(e) => setidealperson(e.target.value)}/>
                                            </div>
                                        </div>
                                       
                                       
                                        <div className="col-lg-12 col-md-12">
                                            <button type="button" onClick={UpdateData} className="default-btn">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
