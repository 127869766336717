import React from 'react'

export default function Menu() {
    return (
        <div>
            {/* Start Sidemenu Area */}
            <div className="sidemenu-area">
                <div className="responsive-burger-menu d-lg-none d-block">
                    <span className="top-bar" />
                    <span className="middle-bar" />
                    <span className="bottom-bar" />
                </div>
                <div className="sidemenu-body">
                    <ul className="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
                        <li className="nav-item active">
                            <a href="/home" className="nav-link">
                                <span className="icon"><i className="fa fa-sticky-note" /></span>
                                <span className="menu-title">News Feed</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/notifications" className="nav-link">
                                <span className="icon"><i className="fa fa-bell" /></span>
                                <span className="menu-title">Notifications</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/assignments" className="nav-link">
                                <span className="icon"><i className="fa fa-tasks" /></span>
                                <span className="menu-title">Assignments</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/announcements" className="nav-link">
                                <span className="icon"><i className="fa fa-bullhorn" /></span>
                                <span className="menu-title">Announcement</span>
                            </a>
                        </li>
                        
                        {/* <li className="nav-item">
                            <a href="/groups" className="nav-link">
                                <span className="icon"><i className="flaticon-team" /></span>
                                <span className="menu-title">Groups</span>
                            </a>
                        </li> */}
                        <li className="nav-item" >
                            <a href="/favorites" className="nav-link" >
                                <span className="icon"><i className="fa fa-star" /></span>
                                <span className="menu-title" >Favorites &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </a>
                        </li>
                        <li className="nav-item" >
                            <a href="/ads" className="nav-link" >
                                <span className="icon"><i className="fa fa-ad" /></span>
                                <span className="menu-title" >Ads &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/events" className="nav-link">
                                <span className="icon"><i className="fa fa-calendar" /></span>
                                <span className="menu-title">Events &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/live-chat" className="nav-link">
                                <span className="icon"><i className="fa fa-keyboard" /></span>
                                <span className="menu-title">Live Chat &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/live-meeting" className="nav-link">
                                <span className="icon"><i className="fa fa-video" /></span>
                                <span className="menu-title">Live Meeting</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/help" className="nav-link">
                                <span className="icon"><i className="fa fa-question-circle" /></span>
                                <span className="menu-title">Help &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a href="/birthday" className="nav-link">
                                <span className="icon"><i className="flaticon-cake" /></span>
                                <span className="menu-title">Birthday</span>
                            </a>
                        </li> */}
                        
                        
                        
                    </ul>
                </div>
            </div>
            {/* End Sidemenu Area */}

        </div>
    )
}
