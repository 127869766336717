import React, { useEffect, useState } from 'react'
import '../.././App.css';
import Swal from 'sweetalert2';

export default function Home() {

    const [carAmbtn, setcarAmbtn] = useState(false);
    let user = JSON.parse(localStorage.getItem('user_info'));
    const [profile_data, setData] = useState([]);
    const [Intsub, setIntsubData] = useState([]);
    const [carAmbData, setcarAmbData] = useState("");
    const [subjects, setsubjects] = useState("");

    const user_id = user['user_id'];
    const login_id = user['id'];
    const type = 'Student';

    useEffect(async () => {
        getData();
        getIntrestSubjects();
    }, [])
    async function UpdateData() {
        const form_data = new FormData();
        form_data.append('user_id', user_id);
        form_data.append('carAmbData', carAmbData);
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/update_students1", { method: 'POST', body: form_data });
        result = await result.json();
        if (result === 1) { Swal.fire('Success!', 'Carrier Ambition Successfully Updated', 'success') }
        else { Swal.fire('Error!', 'Something Was Wrong, Please Try Again', 'error') }
        getData();
    }
    async function AddIntSubjects() {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('user_id', user_id);
        form_data.append('subjects', subjects);
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/insert_intrestsub", { method: 'POST', body: form_data });
        result = await result.json();
        if (result === 1) { Swal.fire('Success!', 'Intrested SUbjects  Successfully Updated', 'success') }
        else { Swal.fire('Error!', 'Something Was Wrong, Please Try Again', 'error') }
        getIntrestSubjects();
    }
    async function getData() {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);
        setcarAmbData(result.other2);
    }
    async function getIntrestSubjects() {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_studsubjects/" + user_id);
        result = await result.json();
        setIntsubData(result);
        setsubjects("");
        console.log(result);
    }
    async function delIntSubjects(id) {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/delete_studsubjects_" + id, {
            method: 'DELETE'
        }, []);
        result = await result.json();
        if (result === 1) {
            Swal.fire('Success!', 'Intrested SUbjects  Successfully Deleted!', 'success')
            getIntrestSubjects();
        } else {
            Swal.fire('Sorry!', 'Something was Wrong!', 'error')
        }
    }
    return (
        <div>
            <div className="content-page-box-area">
                <div className="my-profile-inner-box">
                    <div className="profile-cover-image">
                        <a href="#">
                            <img src="assets/images/my-profile-bg.jpg" alt="image" />
                        </a>
                        {/* <a href="#" className="edit-cover-btn" >Edit Cover</a> */}
                    </div>
                    <div className="profile-info-box">
                        <div className="inner-info-box d-flex justify-content-between align-items-center">
                            <div className="info-image">
                                <a href="#">
                                    {
                                        profile_data.other1 === '' ?
                                            <img src="assets/images/dummy.png" alt="image" />
                                            :
                                            <img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} alt="image" height="200" width="200" />
                                    }
                                </a>
                                <div className="icon">
                                    <a href="/edit-profile" ><i className="flaticon-photo-camera" /></a>
                                </div>
                            </div>
                            <div className="info-text ms-3">
                                <h3><a href="#">{user && user.name}</a></h3>
                                <span>{profile_data.college_name}</span>
                            </div>
                            <ul className="statistics">
                                <li>
                                    <a href="#">
                                        <span className="item-number">59862</span>
                                        <span className="item-text">Assignments</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span className="item-number">8591</span>
                                        <span className="item-text">Posts</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span className="item-number">784514</span>
                                        <span className="item-text">Followers</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="profile-list-tabs">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">

                                <li className="nav-item">
                                    <a className="nav-link active" id="about-tab" data-bs-toggle="tab" href="#about" role="tab" aria-controls="about">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " id="timeline-tab" data-bs-toggle="tab" href="#timeline" role="tab" aria-controls="timeline">Timeline</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="friends-tab" data-bs-toggle="tab" href="#friends" role="tab" aria-controls="friends">Friends</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="photos-tab" data-bs-toggle="tab" href="#photos" role="tab" aria-controls="photos">Photos</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="about" role="tabpanel">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="about-personal-information" >
                                    <div className="about-header d-flex justify-content-between align-items-center">
                                        <div className="title">Personal Information</div>
                                        <div className="dropdown">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item d-flex align-items-center" href="/edit-profile"><i className="flaticon-edit" /> Edit Profile Data</a></li>
                                                {/* <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Information</a></li> */}
                                                {/* <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Information</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <hr />
                                    <ul className="information-list">
                                        <li><span>Nick Name :</span> {profile_data.nick_name} </li><br /><br />
                                        <li><span>Email :</span> {profile_data.email} </li><br /><br />
                                        <li><span>Phone :</span> <a href="tel:{profile_data.phone}">{profile_data.phone}</a> </li><br /><br />
                                        <li><span>Parent Phone :</span> <a href="tel:{profile_data.parent_phone}">{profile_data.parent_phone}</a> </li><br /><br />
                                        <li> <span>Class / Div :</span> {profile_data.class} / {profile_data.division}</li><br /><br />
                                        <li> <span>Username :</span> {user && user.email} </li><br /><br />
                                        <li><span>Languages :</span> {profile_data.language}</li><br /><br />
                                        <li> <span>Age :</span> {profile_data.age} </li><br /><br />
                                        <li> <span>Birth Date :</span> {profile_data.birth_date} </li><br /><br />
                                        <li><span>Gender :</span> {profile_data.gender} </li><br /><br />
                                        <li><span>Blood Group :</span> {profile_data.blood_group} </li><br /><br />
                                        <li><span>Ideal Person :</span> {profile_data.ideal_persons} </li><br /><br />
                                        <hr />
                                        <li><span>Address :</span> {profile_data.address} </li><br /><br />
                                        <li><span>City :</span> {profile_data.city} </li><br /><br />
                                        <li><span>Pin Code :</span> {profile_data.pinocode} </li><br /><br />
                                        <li><span>State :</span> {profile_data.state} </li><br /><br />
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="about-details-information">
                                    <div className="information-box-content">
                                        <div className="information-header d-flex justify-content-between align-items-center">
                                            <div className="title">Carreir Ambition</div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a onClick={() => setcarAmbtn(true)} className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Carreir Ambition</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="content">
                                            {
                                                carAmbtn ?
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-10 col-md-10">
                                                                <div className="news-feed news-feed-form">
                                                                    <form>
                                                                        <div className="form-group">
                                                                            <textarea className="form-control" value={carAmbData} onChange={(e) => setcarAmbData(e.target.value)}></textarea>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2">
                                                                <button className="btn btn-primary" onClick={UpdateData}>Save</button>
                                                            </div>
                                                        </div><hr />
                                                    </div>
                                                    :
                                                    <p>{profile_data.other2}</p>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="about-details-information">
                                    <div className="information-box-content">
                                        <div className="information-header d-flex justify-content-between align-items-center">
                                            <div className="title">Intrested Subjects</div>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="news-feed news-feed-form">
                                                        <form>
                                                            <div className="form-group">
                                                                <input type="text" value={subjects} onChange={(e) => setsubjects(e.target.value)} className="form-control" placeholder="Enter your favorite subjects...." />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2">
                                                    <button className="btn btn-primary" onClick={AddIntSubjects}>Save</button>
                                                </div>
                                            </div><hr />
                                            <div>
                                                <table className="table">
                                                    {
                                                        Intsub.map((item) =>
                                                            <tr>
                                                                <td style={{float:"left"}}> <i className="fa fa-star"></i>&nbsp;&nbsp; {item.subjects} </td>
                                                                <td> <a href="javascript:void(0);" onClick={() => delIntSubjects(item.id)} style={{color:"red"}}><i className="fa fa-trash"></i> Delete</a> </td>
                                                            </tr>
                                                        )
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade " id="timeline" role="tabpanel">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <aside className="widget-area">
                                    <div className="widget widget-suggested-groups">
                                        <h3 className="widget-title">Suggested Groups</h3>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg1" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">UX/UI Design Group</a>
                                                </h4>
                                                <span>5000+ Members</span>
                                                <a href="#" className="join-btn">Join Community</a>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg2" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Job Search Group</a>
                                                </h4>
                                                <span>5000+ Members</span>
                                                <a href="#" className="join-btn">Join Community</a>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg3" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Photography Group</a>
                                                </h4>
                                                <span>5000+ Members</span>
                                                <a href="#" className="join-btn">Join Community</a>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="widget widget-advertisement">
                                        <h3 className="widget-title">Advertisement</h3>
                                        <div className="advertisement-image">
                                            <a href="#"><img src="assets/images/advertisement.jpg" alt="image" /></a>
                                        </div>
                                    </div>
                                    <div className="widget widget-page-you-like">
                                        <h3 className="widget-title">Page You Like</h3>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg1" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Graphic Design</a>
                                                </h4>
                                                <span>1215 Members</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg2" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Digital Marketing</a>
                                                </h4>
                                                <span>1865 Members</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg3" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Fitness Club</a>
                                                </h4>
                                                <span>2051 Members</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg4" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Dream Restaurant</a>
                                                </h4>
                                                <span>5214 Members</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg5" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Travel Life</a>
                                                </h4>
                                                <span>9589 Members</span>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="widget widget-watch-video">
                                        <h3 className="widget-title">Watch Video</h3>
                                        <div className="watch-video-slides owl-carousel owl-theme">
                                            <div className="video-item">
                                                <img src="assets/images/watch-video/video-1.jpg" alt="image" />
                                                <a href="https://www.youtube.com/watch?v=ODfy2YIKS1M" className="video-btn popup-youtube">
                                                    <i className="ri-play-fill" />
                                                </a>
                                            </div>
                                            <div className="video-item">
                                                <img src="assets/images/watch-video/video-2.jpg" alt="image" />
                                                <a href="https://www.youtube.com/watch?v=ODfy2YIKS1M" className="video-btn popup-youtube">
                                                    <i className="ri-play-fill" />
                                                </a>
                                            </div>
                                            <div className="video-item">
                                                <img src="assets/images/watch-video/video-3.jpg" alt="image" />
                                                <a href="https://www.youtube.com/watch?v=ODfy2YIKS1M" className="video-btn popup-youtube">
                                                    <i className="ri-play-fill" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="news-feed-area">
                                    <div className="news-feed news-feed-form">
                                        <h3 className="news-feed-title">Create New Post</h3>
                                        <form>
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" placeholder="Write something here..." defaultValue={""} />
                                            </div>
                                            <ul className="button-group d-flex justify-content-between align-items-center">
                                                <li className="photo-btn">
                                                    <button type="submit"><i className="flaticon-gallery" /> Photo</button>
                                                </li>
                                                <li className="video-btn">
                                                    <button type="submit"><i className="flaticon-video" /> Video</button>
                                                </li>
                                                <li className="tag-btn">
                                                    <button type="submit"><i className="flaticon-tag" /> Tag Friends</button>
                                                </li>
                                                <li className="post-btn">
                                                    <button type="submit">Post</button>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                    <div className="news-feed news-feed-stories">
                                        <div className="stories-title d-flex justify-content-between align-items-center">
                                            <h3>Stories</h3>
                                            <span><a href="#">See All</a></span>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <div className="stories-image">
                                                        <a href="#">
                                                            <img src="assets/images/user/user-1.jpg" alt="image" />
                                                        </a>
                                                        <div className="add-icon">
                                                            <a href="#"><i className="flaticon-add" /></a>
                                                        </div>
                                                    </div>
                                                    <span><a href="#">Add Story</a></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-28.jpg" alt="image" />
                                                    </a>
                                                    <span><a href="#">Jimenez</a></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-29.jpg" alt="image" />
                                                    </a>
                                                    <span><a href="#">Lolita</a></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-13.jpg" alt="image" />
                                                    </a>
                                                    <span><a href="#">Matthew</a></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-30.jpg" alt="image" />
                                                    </a>
                                                    <span><a href="#">Russell</a></span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-sm-4 col-4">
                                                <div className="stories-item">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-31.jpg" alt="image" />
                                                    </a>
                                                    <span><a href="#">Katzman</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="news-feed news-feed-post">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <a href="my-profile.html"><img src="assets/images/user/user-32.jpg" className="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div className="info ms-3">
                                                <span className="name"><a href="my-profile.html">Julie R. Morleyv</a></span>
                                                <span className="small-text"><a href="#">10 Mins Ago</a></span>
                                            </div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Post</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Donec rutrum congue leo eget malesuada. Praesent sapien massa convallis a pellentesque nec egestas non nisi. Curabitur non nulla sit amet nisl tempus convallis quis.</p>
                                            <div className="post-image">
                                                <img src="assets/images/news-feed-post/post-1.jpg" alt="image" />
                                            </div>
                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                <li className="post-react">
                                                    <a href="#"><i className="flaticon-like" /><span>Like</span> <span className="number">1499 </span></a>
                                                    <ul className="react-list">
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-1.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-2.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-3.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-4.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-5.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-6.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-7.png" alt="Like" /></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="post-comment">
                                                    <a href="#"><i className="flaticon-comment" /><span>Comment</span> <span className="number">599 </span></a>
                                                </li>
                                                <li className="post-share">
                                                    <a href="#"><i className="flaticon-share" /><span>Share</span> <span className="number">24 </span></a>
                                                </li>
                                            </ul>
                                            <div className="post-comment-list">
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-14.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">David Moore</a>
                                                        </h3>
                                                        <span>5 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat donec rutrum congue leo eget malesuada donec rutrum congue leo eget malesuada. Praesent sapien massa convallis a pellentesque non nisi curabitur non nulla sit amet nisl tempus convallis lectus.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(2)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-15.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">Claire P. Toy</a>
                                                        </h3>
                                                        <span>45 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada praesent sapien massa convallis a pellentesque nec egestas non nisi curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(12)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-16.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">Karen Williams</a>
                                                        </h3>
                                                        <span>5 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat donec rutrum congue leo eget.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(2)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="more-comments">
                                                    <a href="#">More Comments+</a>
                                                </div>
                                            </div>
                                            <form className="post-footer">
                                                <div className="footer-image">
                                                    <a href="#"><img src="assets/images/user/user-1.jpg" className="rounded-circle" alt="image" /></a>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..." defaultValue={""} />
                                                    <label><a href="#"><i className="flaticon-photo-camera" /></a></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="news-feed news-feed-post">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <a href="my-profile.html"><img src="assets/images/user/user-35.jpg" className="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div className="info ms-3">
                                                <span className="name"><a href="my-profile.html">William Fenton</a></span>
                                                <span className="small-text"><a href="#">50 Mins Ago</a></span>
                                            </div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Post</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>💗</p>
                                            <div className="post-image">
                                                <img src="assets/images/news-feed-post/post-2.jpg" alt="image" />
                                            </div>
                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                <li className="post-react">
                                                    <a href="#"><i className="flaticon-like" /><span>Like</span> <span className="number">3 </span></a>
                                                    <ul className="react-list">
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-1.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-2.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-3.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-4.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-5.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-6.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-7.png" alt="Like" /></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="post-comment">
                                                    <a href="#"><i className="flaticon-comment" /><span>Comment</span> <span className="number">0 </span></a>
                                                </li>
                                                <li className="post-share">
                                                    <a href="#"><i className="flaticon-share" /><span>Share</span> <span className="number">0 </span></a>
                                                </li>
                                            </ul>
                                            <form className="post-footer">
                                                <div className="footer-image">
                                                    <a href="#"><img src="assets/images/user/user-2.jpg" className="rounded-circle" alt="image" /></a>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..." defaultValue={""} />
                                                    <label><a href="#"><i className="flaticon-photo-camera" /></a></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="news-feed news-feed-post">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <a href="my-profile.html"><img src="assets/images/user/user-36.jpg" className="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div className="info ms-3">
                                                <span className="name"><a href="my-profile.html">Maria Dodson</a></span>
                                                <span className="small-text"><a href="#">2 Hours Ago</a></span>
                                            </div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Post</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Keep Smiling 🧡</p>
                                            <div className="post-image">
                                                <img src="assets/images/news-feed-post/post-3.jpg" alt="image" />
                                            </div>
                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                <li className="post-react">
                                                    <a href="#"><i className="flaticon-like" /><span>Like</span> <span className="number">15 </span></a>
                                                    <ul className="react-list">
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-1.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-2.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-3.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-4.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-5.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-6.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-7.png" alt="Like" /></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="post-comment">
                                                    <a href="#"><i className="flaticon-comment" /><span>Comment</span> <span className="number">0 </span></a>
                                                </li>
                                                <li className="post-share">
                                                    <a href="#"><i className="flaticon-share" /><span>Share</span> <span className="number">5 </span></a>
                                                </li>
                                            </ul>
                                            <form className="post-footer">
                                                <div className="footer-image">
                                                    <a href="#"><img src="assets/images/user/user-15.jpg" className="rounded-circle" alt="image" /></a>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..." defaultValue={""} />
                                                    <label><a href="#"><i className="flaticon-photo-camera" /></a></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="news-feed news-feed-post">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <a href="my-profile.html"><img src="assets/images/user/user-5.jpg" className="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div className="info ms-3">
                                                <span className="name"><a href="my-profile.html">Karen Williams</a></span>
                                                <span className="small-text"><a href="#">10 Mins Ago</a></span>
                                            </div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Post</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada.</p>
                                            <div className="post-image">
                                                <img src="assets/images/news-feed-post/post-4.jpg" alt="image" />
                                            </div>
                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                <li className="post-react">
                                                    <a href="#"><i className="flaticon-like" /><span>Like</span> <span className="number">1499 </span></a>
                                                    <ul className="react-list">
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-1.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-2.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-3.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-4.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-5.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-6.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-7.png" alt="Like" /></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="post-comment">
                                                    <a href="#"><i className="flaticon-comment" /><span>Comment</span> <span className="number">599 </span></a>
                                                </li>
                                                <li className="post-share">
                                                    <a href="#"><i className="flaticon-share" /><span>Share</span> <span className="number">24 </span></a>
                                                </li>
                                            </ul>
                                            <div className="post-comment-list">
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-21.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">David Moore</a>
                                                        </h3>
                                                        <span>5 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat donec rutrum congue leo eget malesuada donec rutrum congue leo eget malesuada. Praesent sapien massa convallis a pellentesque non nisi curabitur non nulla sit amet nisl tempus convallis lectus.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(2)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-22.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">Claire P. Toy</a>
                                                        </h3>
                                                        <span>45 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada praesent sapien massa convallis a pellentesque nec egestas non nisi curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(12)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-list">
                                                    <div className="comment-image">
                                                        <a href="my-profile.html"><img src="assets/images/user/user-24.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <a href="my-profile.html">Karen Williams</a>
                                                        </h3>
                                                        <span>5 Mins Ago</span>
                                                        <p>Donec rutrum congue leo eget malesuada nulla quis lorem ut libero malesuada feugiat donec rutrum congue leo eget.</p>
                                                        <ul className="comment-react">
                                                            <li><a href="#" className="like">Like(2)</a></li>
                                                            <li><a href="#">Reply</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="more-comments">
                                                    <a href="#">More Comments+</a>
                                                </div>
                                            </div>
                                            <form className="post-footer">
                                                <div className="footer-image">
                                                    <a href="#"><img src="assets/images/user/user-19.jpg" className="rounded-circle" alt="image" /></a>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..." defaultValue={""} />
                                                    <label><a href="#"><i className="flaticon-photo-camera" /></a></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="news-feed news-feed-post">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <a href="my-profile.html"><img src="assets/images/user/user-18.jpg" className="rounded-circle" alt="image" /></a>
                                            </div>
                                            <div className="info ms-3">
                                                <span className="name"><a href="my-profile.html">Maria Dodson</a></span>
                                                <span className="small-text"><a href="#">2 Hours Ago</a></span>
                                            </div>
                                            <div className="dropdown">
                                                <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="flaticon-menu" /></button>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-edit" /> Edit Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-private" /> Hide Post</a></li>
                                                    <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="flaticon-trash" /> Delete Post</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post-body">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <div className="post-image">
                                                <img src="assets/images/news-feed-post/post-5.jpg" alt="image" />
                                            </div>
                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                <li className="post-react">
                                                    <a href="#"><i className="flaticon-like" /><span>Like</span> <span className="number">15 </span></a>
                                                    <ul className="react-list">
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-1.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-2.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-3.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-4.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-5.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-6.png" alt="Like" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#"><img src="assets/images/react/react-7.png" alt="Like" /></a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="post-comment">
                                                    <a href="#"><i className="flaticon-comment" /><span>Comment</span> <span className="number">0 </span></a>
                                                </li>
                                                <li className="post-share">
                                                    <a href="#"><i className="flaticon-share" /><span>Share</span> <span className="number">5 </span></a>
                                                </li>
                                            </ul>
                                            <form className="post-footer">
                                                <div className="footer-image">
                                                    <a href="#"><img src="assets/images/user/user-22.jpg" className="rounded-circle" alt="image" /></a>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..." defaultValue={""} />
                                                    <label><a href="#"><i className="flaticon-photo-camera" /></a></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="load-more-posts-btn">
                                        <a href="#"><i className="flaticon-loading" /> Load More Posts</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <aside className="widget-area">
                                    <div className="widget widget-birthday">
                                        <div className="birthday-title d-flex justify-content-between align-items-center">
                                            <h3>Today Birthdays</h3>
                                            <span><a href="#">See All</a></span>
                                        </div>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg1" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Earline Benally</a>
                                                </h4>
                                                <span>Today</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg2" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Jack Gulley</a>
                                                </h4>
                                                <span>Today</span>
                                            </div>
                                        </article>
                                        <div className="birthday-title d-flex justify-content-between align-items-center">
                                            <h3>Recent Birthdays</h3>
                                            <span><a href="#">See All</a></span>
                                        </div>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg3" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Lolita Benally</a>
                                                </h4>
                                                <span>May 18</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg4" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Russell Gulley</a>
                                                </h4>
                                                <span>May 20</span>
                                            </div>
                                        </article>
                                        <div className="birthday-title d-flex justify-content-between align-items-center">
                                            <h3>Coming Birthdays</h3>
                                            <span><a href="#">See All</a></span>
                                        </div>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg5" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Cindy L. Wilson</a>
                                                </h4>
                                                <span>July 18</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg6" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">Patricia E. Looney</a>
                                                </h4>
                                                <span>July 20</span>
                                            </div>
                                        </article>
                                        <article className="item">
                                            <a href="#" className="thumb">
                                                <span className="fullimage bg7" role="img" />
                                            </a>
                                            <div className="info">
                                                <h4 className="title">
                                                    <a href="#">James G. Nelson</a>
                                                </h4>
                                                <span>July 21</span>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="widget widget-explore-events">
                                        <h3 className="widget-title">Explore Events</h3>
                                        <article className="item">
                                            <a href="#"><img src="assets/images/explore-events/explore-1.jpg" alt="image" /></a>
                                        </article>
                                        <article className="item">
                                            <a href="#"><img src="assets/images/explore-events/explore-2.jpg" alt="image" /></a>
                                        </article>
                                    </div>
                                    <div className="widget widget-who-following">
                                        <h3 className="widget-title">Who's Following</h3>
                                        <div className="following-item d-flex justify-content-between align-items-center">
                                            <a href="#"><img src="assets/images/user/user-42.jpg" className="rounded-circle" alt="image" /></a>
                                            <span className="name"><a href="#">Shawn Lynch</a></span>
                                            <span className="add-friend"><a href="#">Add</a></span>
                                        </div>
                                        <div className="following-item d-flex justify-content-between align-items-center">
                                            <a href="#"><img src="assets/images/user/user-43.jpg" className="rounded-circle" alt="image" /></a>
                                            <span className="name"><a href="#">Kenneth Perry</a></span>
                                            <span className="add-friend"><a href="#">Add</a></span>
                                        </div>
                                        <div className="following-item d-flex justify-content-between align-items-center">
                                            <a href="#"><img src="assets/images/user/user-44.jpg" className="rounded-circle" alt="image" /></a>
                                            <span className="name"><a href="#">Janet Suarez</a></span>
                                            <span className="add-friend"><a href="#">Add</a></span>
                                        </div>
                                        <div className="following-item d-flex justify-content-between align-items-center">
                                            <a href="#"><img src="assets/images/user/user-45.jpg" className="rounded-circle" alt="image" /></a>
                                            <span className="name"><a href="#">Brian Mingo</a></span>
                                            <span className="add-friend"><a href="#">Add</a></span>
                                        </div>
                                        <div className="following-item d-flex justify-content-between align-items-center">
                                            <a href="#"><img src="assets/images/user/user-46.jpg" className="rounded-circle" alt="image" /></a>
                                            <span className="name"><a href="#">Julia Ramos</a></span>
                                            <span className="add-friend"><a href="#">Add</a></span>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="friends" role="tabpanel">
                        <div className="friends-inner-box-style d-flex justify-content-between align-items-center">
                            <div className="title">
                                <h3>Friends</h3>
                                <span>199</span>
                            </div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="all-friends-tab" data-bs-toggle="tab" href="#all-friends" role="tab" aria-controls="all-friends">All Friends</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="recently-added-tab" data-bs-toggle="tab" href="#recently-added" role="tab" aria-controls="recently-added">Recently Added</a>
                                </li>
                            </ul>
                            <div className="friends-search-box">
                                <form>
                                    <input type="text" className="input-search" placeholder="Search friends..." />
                                    <button type="submit"><i className="ri-search-line" /></button>
                                </form>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="all-friends" role="tabpanel">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-1.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-1.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Jose Marroquin</a></h3>
                                                        <span>10 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">862</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">91</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">514</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-2.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-2.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Myrtle Lewis</a></h3>
                                                        <span>45 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">82</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">50</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">314</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-3.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-3.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Howard Tam</a></h3>
                                                        <span>19 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">452</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">120</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">328</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-4.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-4.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Kimberly Blum</a></h3>
                                                        <span>18 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">685</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">16</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">675</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-5.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-5.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Mary Mercado</a></h3>
                                                        <span>10 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">687</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">152</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">657</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-6.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-6.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Robert Ward</a></h3>
                                                        <span>22 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">156</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">73</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">642</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-7.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-7.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Pauline Hughes</a></h3>
                                                        <span>26 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">483</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">241</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">965</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-8.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-8.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Brad Snowden</a></h3>
                                                        <span>30 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">383</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">541</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">265</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-9.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-9.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Mark S. Perry</a></h3>
                                                        <span>33 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">583</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">441</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">165</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-10.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-10.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Josefina Wells</a></h3>
                                                        <span>45 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">841</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">965</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-11.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-11.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Richard Smith</a></h3>
                                                        <span>65 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">483</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">241</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">865</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-12.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-12.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Alma Smith</a></h3>
                                                        <span>61 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">783</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">541</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">565</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-13.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-13.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Bessie Smith</a></h3>
                                                        <span>24 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">183</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">441</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">765</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-14.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-14.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Anthony Hogg</a></h3>
                                                        <span>75 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">841</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">365</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-15.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-15.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Shel Williams</a></h3>
                                                        <span>25 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">583</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">941</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">265</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-16.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/friends/friends-16.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Carol Miller</a></h3>
                                                        <span>20 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">341</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">565</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="load-more-posts-btn">
                                    <a href="#"><i className="flaticon-loading" /> Load More</a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="recently-added" role="tabpanel">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-1.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-10.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Jose Marroquin</a></h3>
                                                        <span>10 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">862</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">91</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">514</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-2.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-11.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Myrtle Lewis</a></h3>
                                                        <span>45 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">82</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">50</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">314</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-3.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-12.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Howard Tam</a></h3>
                                                        <span>19 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">452</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">120</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">328</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-4.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-13.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Kimberly Blum</a></h3>
                                                        <span>18 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">685</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">16</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">675</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-5.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-14.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Mary Mercado</a></h3>
                                                        <span>10 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">687</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">152</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">657</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-6.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-15.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Robert Ward</a></h3>
                                                        <span>22 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">156</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">73</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">642</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-7.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-16.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Pauline Hughes</a></h3>
                                                        <span>26 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">483</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">241</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">965</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-8.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-17.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Brad Snowden</a></h3>
                                                        <span>30 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">383</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">541</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">265</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-9.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-18.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Mark S. Perry</a></h3>
                                                        <span>33 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">583</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">441</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">165</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-10.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-19.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Josefina Wells</a></h3>
                                                        <span>45 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">841</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">965</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-11.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-20.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Richard Smith</a></h3>
                                                        <span>65 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">483</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">241</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">865</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-12.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-21.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Alma Smith</a></h3>
                                                        <span>61 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">783</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">541</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">565</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-13.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-22.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Bessie Smith</a></h3>
                                                        <span>24 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">183</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">441</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">765</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-14.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-23.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Anthony Hogg</a></h3>
                                                        <span>75 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">841</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">365</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-15.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-24.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Shel Williams</a></h3>
                                                        <span>25 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">583</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">941</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">265</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-friends-card">
                                            <div className="friends-image">
                                                <a href="#">
                                                    <img src="assets/images/friends/friends-bg-16.jpg" alt="image" />
                                                </a>
                                                <div className="icon">
                                                    <a href="#"><i className="flaticon-user" /></a>
                                                </div>
                                            </div>
                                            <div className="friends-content">
                                                <div className="friends-info d-flex justify-content-between align-items-center">
                                                    <a href="#">
                                                        <img src="assets/images/user/user-25.jpg" alt="image" />
                                                    </a>
                                                    <div className="text ms-3">
                                                        <h3><a href="#">Carol Miller</a></h3>
                                                        <span>20 Mutual Friends</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">683</span>
                                                            <span className="item-text">Likes</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">341</span>
                                                            <span className="item-text">Following</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="item-number">565</span>
                                                            <span className="item-text">Followers</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="button-group d-flex justify-content-between align-items-center">
                                                    <div className="add-friend-btn">
                                                        <button type="submit">Add Friend</button>
                                                    </div>
                                                    <div className="send-message-btn">
                                                        <button type="submit">Send Message</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="load-more-posts-btn">
                                    <a href="#"><i className="flaticon-loading" /> Load More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="photos" role="tabpanel">
                        <div className="photos-inner-box-style d-flex justify-content-between align-items-center">
                            <div className="title">
                                <h3>Photos</h3>
                                <span>855</span>
                            </div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="all-photos-tab" data-bs-toggle="tab" href="#all-photos" role="tab" aria-controls="all-photos">All Photos</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="photos-albums-tab" data-bs-toggle="tab" href="#photos-albums" role="tab" aria-controls="photos-albums">Photos Albums</a>
                                </li>
                            </ul>
                            <div className="photos-search-box">
                                <form>
                                    <input type="text" className="input-search" placeholder="Search photos..." />
                                    <button type="submit"><i className="ri-search-line" /></button>
                                </form>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="all-photos" role="tabpanel">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-1.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-2.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-3.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-4.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-5.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-6.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-7.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-8.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-9.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-10.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-11.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-12.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-13.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-14.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-15.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-16.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="load-more-posts-btn">
                                    <a href="#"><i className="flaticon-loading" /> Load More</a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="photos-albums" role="tabpanel">
                                <div className="row justify-content-center">
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-16.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-15.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-14.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-13.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-12.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-11.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-10.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-9.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-8.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-7.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-6.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-5.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-4.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-3.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-2.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="single-photos-card">
                                            <a href=""><img src="assets/images/photos/photos-1.jpg" alt="image" /></a>
                                            <div className="icon">
                                                <a href="#"><i className="flaticon-remove-user" /></a>
                                                <a href="#"><i className="flaticon-download" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="load-more-posts-btn">
                                    <a href="#"><i className="flaticon-loading" /> Load More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
