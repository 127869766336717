import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

export default function Login() {
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('user_info')) {
            history.push('/home')
        }
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    async function login1() {
    const item = {email, password};
        if (email.length===0 || password.length===0) 
        {
            Toast.fire({
                icon: 'error',
                title: 'Please Enter Username Or Password'
            })
        }
         else 
        {
            let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/login1", {
                method: 'POST',
                body: JSON.stringify(item),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            })
            result = await result.json();
            if (result === 1 || result ==='') {
                Swal.fire({
                    title: 'Sorry!',
                    text: 'Please Enter Correct Username or Password',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            } else {
                localStorage.setItem("user_info", JSON.stringify(result))

                Toast.fire({
                    icon: 'success',
                    title: 'Your Are Successfully Login'
                })
                window.location.href = "/home";
            }
     }
    }
    return (
        <div>
            <div className="">
                <div className="container">
                    <div className="row" style={{marginTop:"-20px"}}>
                        <div className="col-lg-6 col-md-12">
                            <div className="profile-authentication-image">
                                <div className="content-image">
                                    <div className="vector-image">
                                        <br/><br/><br/><br/><br/>
                                        <a href="#"><img src="assets/images/logo.png" alt="Digital" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="login-form">
                                <h2>Login</h2>
                                <form>
                                    <div className="form-group">
                                        <label>Username or email</label>
                                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username...."/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password...."/>
                                    </div>
                                    <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                                     <button type="button" className="default-btn" onClick={login1}>Login</button>
                                    </div><br/>
                                    <center>
                                       New Here ? <a href="/register" style={{color:"red"}}>Register Now</a><br/><br/>
                                    </center>
                                    {/* <a href="#" className="lost-your-password">Forgot password ?</a> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
