import React, { useEffect, useState } from 'react'
import '../.././App.css';
import Moment from 'moment';
import Swal from 'sweetalert2';

export default function Home() {

    let user = JSON.parse(localStorage.getItem('user_info'));
    const [profile_data, setData] = useState([]);
    const [post_data, setPostData] = useState([]);
    const [comt, setcomtData] = useState([]);
    const [studComments, setstudComments] = useState([]);
    const [Reaction, setReactionData] = useState([]);
    const [StudReaction, setStudReactionData] = useState([]);

    const login_id = user['id'];
    const user_id = user['user_id'];
    const type = 'Student';
    const [comments, setcomments] = useState("");


    useEffect(async () => {
        getcomments();
    }, [])
    async function getcomments() {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('clg_id', result.college_id);

        let result1 = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_posts ", { method: 'POST', body: form_data });
        result1 = await result1.json();
        setstudComments(result1[4]);
        setStudReactionData(result1[3]);
        setReactionData(result1[2]);
        setcomtData(result1[1]);
        setPostData(result1[0]);
        console.log(result1[0]);

    }
    async function AddComments(post_id) {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('user_id', user_id);
        form_data.append('post_id', post_id);
        form_data.append('type', type);
        form_data.append('comments', comments);
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/insert_comments ", { method: 'POST', body: form_data });
        result = await result.json();
        if (result === 1) { Swal.fire('Success!', 'Your Comments Successfully Added', 'success'); setcomments(""); getcomments(); }
        else { Swal.fire('Error!', 'Something Was Wrong, Please Try Again', 'error') }
    }

    async function AddReaction(post_id, reaction_id) {
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('user_id', user_id);
        form_data.append('post_id', post_id);
        form_data.append('type', type);
        form_data.append('reaction_id', reaction_id);
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/insert_postreactions ", { method: 'POST', body: form_data });
        result = await result.json();
        console.log(result);
        getcomments();
    }
    async function delComments(id) {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/delete_studcomments_" + id, {
            method: 'DELETE'
        }, []);
        result = await result.json();
        if (result === 1) {
            Swal.fire('Success!', 'Comments  Successfully Deleted!', 'success')
            getcomments();
        } else {
            Swal.fire('Sorry!', 'Something was Wrong!', 'error')
        }
    }

    return (
        <div>
            {/* Start Content Page Box Area */}

            <div className="content-page-box-area">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                            <div className="widget widget-view-profile">
                                <div className="profile-box d-flex justify-content-between align-items-center">
                                    {
                                        profile_data.other1 === '' ?
                                            <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" alt="image" /></a>
                                            :
                                            <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} alt="image" /></a>

                                    }

                                    <div className="text ms-2">
                                        <h3><a href="/Profile">{profile_data.name}</a></h3>
                                        <span>{profile_data.college_name}</span>
                                    </div>
                                </div>
                                <ul className="profile-statistics">
                                    <li>
                                        <a>
                                            <span className="item-number">59862</span>
                                            <span className="item-text">Assignments</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="item-number">8591</span>
                                            <span className="item-text">Posts</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span className="item-number">784514</span>
                                            <span className="item-text">Followers</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="profile-btn">
                                    <a href="/Profile" className="default-btn">View Profile</a>
                                </div>
                            </div>
                            <div className="widget widget-advertisement">
                                <h3 className="widget-title">Advertisement</h3>
                                <div className="advertisement-image">
                                    <a><img src="%PUBLIC_URL%/../assets/images/advertisement.jpg" alt="image" /></a>
                                </div>
                            </div>
                            {/* <div className="widget widget-suggested-groups">
                                <h3 className="widget-title">Suggested Colleges</h3>
                                <article className="item">
                                    <a className="thumb">
                                        <span className="fullimage bg1" role="img" />
                                    </a>
                                    <div className="info">
                                        <h4 className="title">
                                            <a>UX/UI Design Group</a>
                                        </h4>
                                        <span>5000+ Members</span>
                                        <a className="join-btn">Join Community</a>
                                    </div>
                                </article>
                                <article className="item">
                                    <a className="thumb">
                                        <span className="fullimage bg2" role="img" />
                                    </a>
                                    <div className="info">
                                        <h4 className="title">
                                            <a>Job Search Group</a>
                                        </h4>
                                        <span>5000+ Members</span>
                                        <a className="join-btn">Join Community</a>
                                    </div>
                                </article>
                                <article className="item">
                                    <a className="thumb">
                                        <span className="fullimage bg3" role="img" />
                                    </a>
                                    <div className="info">
                                        <h4 className="title">
                                            <a>Photography Group</a>
                                        </h4>
                                        <span>5000+ Members</span>
                                        <a className="join-btn">Join Community</a>
                                    </div>
                                </article>
                            </div> */}
                        </aside>
                    </div>
                    <div className="col-lg-7 col-md-12">

                        <div className="news-feed-area">
                            {
                                post_data.map((item, num = 0) =>
                                    item.post_type === "Text" ?
                                        <div className="news-feed news-feed-post" key={item.id}>
                                            <div className="post-header d-flex justify-content-between align-items-center">
                                                <div className="image">
                                                    {
                                                        item.teach_image === null ?
                                                            <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" alt="image" /></a>
                                                            :
                                                            <a><img src={'https://collegeblogbackend.digitaljayesh.com/' + item.teach_image} alt="image" className="rounded-circle" /></a>
                                                    }
                                                </div>
                                                <div className="info ms-3">
                                                    <span className="name"><a>{item.name}</a></span>
                                                    <span className="small-text"><a>{Moment().diff(Moment(item.created_at).format('YYYY-MM-DD HH:MM:SS'), 'hours')} Hours Ago</a></span>
                                                </div>
                                                <div className="info ms-3"> </div>
                                                <div className="info ms-3"> </div>
                                            </div><hr />
                                            <div className="post-body">
                                                <h4 style={{ textAlign: "left" }}>{item.post_title}</h4><br />
                                                <p style={{ textAlign: "left" }}>{item.post_descreption}</p>
                                                <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                    <li className="post-react">
                                                    {
                                                                    Reaction[num][0]['count'] == 0 || item.reaction == null ?
                                                                    <a><i className="flaticon-like" /><span>Like</span> <span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                                    :
                                                                    <a><img src={"%PUBLIC_URL%/../assets/images/react/react-" + item.reaction+ ".png"} /> <span> {item.reaction=== '1' ? 'Like' : item.reaction=== '2' ? 'Love' : item.reaction=== '3' ? 'Care' : item.reaction=== '4' ? 'HaHa' : item.reaction=== '5' ? 'Wow' : item.reaction=== '6' ? 'Sad' : 'Angry'}</span><span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                            }
                                                        <ul className="react-list">
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 1)}><img src="%PUBLIC_URL%/../assets/images/react/react-1.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 2)}><img src="%PUBLIC_URL%/../assets/images/react/react-2.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 3)}><img src="%PUBLIC_URL%/../assets/images/react/react-3.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 4)}><img src="%PUBLIC_URL%/../assets/images/react/react-4.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 5)}><img src="%PUBLIC_URL%/../assets/images/react/react-5.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 6)}><img src="%PUBLIC_URL%/../assets/images/react/react-6.png" alt="Like" /></a>
                                                            </li>
                                                            <li>
                                                                <a onClick={() => AddReaction(item.id, 7)}><img src="%PUBLIC_URL%/../assets/images/react/react-7.png" alt="Like" /></a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="post-comment">
                                                        <a><i className="flaticon-comment" /><span>Comment</span> <span className="number">{comt.lenth === '' ? null : comt[num][0]['count']}</span></a>
                                                    </li>
                                                </ul>
                                                <form className="post-footer">
                                                    <div className="footer-image">
                                                        {
                                                            profile_data.other1 === '' ?
                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                :
                                                                <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>
                                                        }
                                                    </div>
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div className="form-group">
                                                                        <textarea name="message" value={comments} onChange={(e) => setcomments(e.target.value)} className="form-control" placeholder="Write a comment..." />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        comments === '' ?
                                                                            null
                                                                            :
                                                                            <div className="profile-btn">
                                                                                <a className="default-btn" onClick={() => AddComments(item.id)} ><i className="fa fa-paper-plane" /></a>
                                                                            </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </form>
                                                <div className="post-comment-list">
                                                        {
                                                            studComments.map((c_data) =>
                                                                item.id == c_data.post_id ?
                                                                    <div className="comment-list">
                                                                        <div className="comment-image">
                                                                            {
                                                                                profile_data.other1 === '' ?
                                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                                :
                                                                                <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>
                                                                            }
                                                                        </div>
                                                                        <div style={{float:"right"}}>
                                                                            {/* <a className="btn btn-sm btn-success" ><i className="fa fa-edit"></i></a> */}
                                                                            &nbsp;<a className="btn btn-sm btn-danger" onClick={() => delComments(c_data.id)}><i className="fa fa-trash"></i></a>
                                                                        </div>
                                                                        <div className="comment-info">
                                                                            <h3>{user && user.name} </h3>
                                                                            <div>
                                                                                <span>{Moment(c_data.created_at).format('DD/MM/YYYY HH:MM A')}</span>
                                                                                <p>{c_data.comments}</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr /></div>
                                                                    :
                                                                    null
                                                            )}
                                                    </div>
                                            </div>
                                        </div>
                                        :
                                        item.post_type === "Youtube Link" ?
                                            <div className="news-feed news-feed-post" key={item.id}>
                                                <div className="post-header d-flex justify-content-between align-items-center">
                                                    <div className="image">
                                                        {
                                                            item.teach_image === '' ?
                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" alt="image" /></a>
                                                                :
                                                                <a><img src={'https://collegeblogbackend.digitaljayesh.com/' + item.teach_image} alt="image" className="rounded-circle" /></a>
                                                        }
                                                    </div>
                                                    <div className="info ms-2" >
                                                        <span className="name"><a>{item.name}</a></span>
                                                        <span className="small-text"><a>{Moment().diff(Moment(item.created_at).format('YYYY-MM-DD HH:MM:SS'), 'hours')} Hours Ago</a></span>
                                                    </div>
                                                    <div className="info ms-4"></div>
                                                    <div className="info ms-4"></div>
                                                </div><hr />
                                                <div className="post-body">
                                                    <p style={{ textAlign: "left" }}>{item.post_descreption}</p>
                                                    <div className="post-image">
                                                        <iframe width="100%" height="300" src={item.post_title}
                                                            title={item.post_title}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" >
                                                        </iframe>
                                                    </div>
                                                    <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                        <li className="post-react">
                                                            {
                                                                    Reaction[num][0]['count'] == 0 || item.reaction == null ?
                                                                    <a><i className="flaticon-like" /><span>Like</span> <span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                                    :
                                                                    <a><img src={"%PUBLIC_URL%/../assets/images/react/react-" + item.reaction+ ".png"} /> <span> {item.reaction=== '1' ? 'Like' : item.reaction=== '2' ? 'Love' : item.reaction=== '3' ? 'Care' : item.reaction=== '4' ? 'HaHa' : item.reaction=== '5' ? 'Wow' : item.reaction=== '6' ? 'Sad' : 'Angry'}</span><span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                            }
                                                            <ul className="react-list">
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 1)}><img src="%PUBLIC_URL%/../assets/images/react/react-1.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 2)}><img src="%PUBLIC_URL%/../assets/images/react/react-2.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 3)}><img src="%PUBLIC_URL%/../assets/images/react/react-3.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 4)}><img src="%PUBLIC_URL%/../assets/images/react/react-4.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 5)}><img src="%PUBLIC_URL%/../assets/images/react/react-5.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 6)}><img src="%PUBLIC_URL%/../assets/images/react/react-6.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 7)}><img src="%PUBLIC_URL%/../assets/images/react/react-7.png" alt="Like" /></a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="post-comment">
                                                            <a><i className="flaticon-comment" /><span>Comment</span> <span className="number">{comt.lenth === '' ? null : comt[num][0]['count']}</span></a>
                                                        </li>

                                                    </ul>
                                                    <form className="post-footer">
                                                        <div className="footer-image">
                                                            {
                                                                profile_data.other1 === '' ?
                                                                    <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                    :
                                                                    <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>

                                                            }
                                                        </div>
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            <textarea name="message" value={comments} onChange={(e) => setcomments(e.target.value)} className="form-control" placeholder="Write a comment..." />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            comments === '' ?
                                                                                null
                                                                                :
                                                                                <div className="profile-btn">
                                                                                    <a className="default-btn" onClick={() => AddComments(item.id)} ><i className="fa fa-paper-plane" /></a>
                                                                                </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </form>
                                                    <div className="post-comment-list">
                                                        {
                                                            studComments.map((c_data) =>
                                                                item.id == c_data.post_id ?
                                                                    <div className="comment-list">
                                                                        <div className="comment-image">
                                                                            {
                                                                                profile_data.other1 === '' ?
                                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                                :
                                                                                <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>
                                                                            }
                                                                        </div>
                                                                        <div style={{float:"right"}}>
                                                                            {/* <a className="btn btn-sm btn-success" ><i className="fa fa-edit"></i></a> */}
                                                                            &nbsp;<a className="btn btn-sm btn-danger" onClick={() => delComments(c_data.id)}><i className="fa fa-trash"></i></a>
                                                                        </div>
                                                                        <div className="comment-info">
                                                                            <h3>{user && user.name} </h3>
                                                                            <div>
                                                                                <span>{Moment(c_data.created_at).format('DD/MM/YYYY HH:MM A')}</span>
                                                                                <p>{c_data.comments}</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr /></div>
                                                                    :
                                                                    null
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="news-feed news-feed-post" key={item.id}>
                                                <div className="post-header d-flex justify-content-between align-items-center">
                                                    <div className="image">
                                                        {
                                                            item.teach_image === '' ?
                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" alt="image" /></a>
                                                                :
                                                                <a><img src={'https://collegeblogbackend.digitaljayesh.com/' + item.teach_image} alt="image" className="rounded-circle" /></a>
                                                        }
                                                    </div>
                                                    <div className="info ms-3">
                                                        <span className="name"><a>{item.name}</a></span>
                                                        <span className="small-text"><a>{Moment().diff(Moment(item.created_at).format('YYYY-MM-DD HH:MM:SS'), 'hours')} Hours Ago</a></span>
                                                    </div>
                                                    <div className="info ms-3"></div>
                                                    <div className="info ms-3"> </div>
                                                </div><hr />
                                                <div className="post-body">
                                                    <p style={{ textAlign: "left" }}>{item.post_descreption}</p>
                                                    <div className="post-image">
                                                        <img className="post-img" src={'https://collegeblogbackend.digitaljayesh.com/' + item.post_title} alt="image" />
                                                    </div>
                                                    <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                        <li className="post-react">
                                                        {
                                                                    Reaction[num][0]['count'] == 0 || item.reaction == null || item.studentlogin_id===login_id?
                                                                    <a><i className="flaticon-like" /><span>Like</span> <span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                                    :
                                                                    <a><img src={"%PUBLIC_URL%/../assets/images/react/react-" + item.reaction+ ".png"} /> <span> {item.reaction=== '1' ? 'Like' : item.reaction=== '2' ? 'Love' : item.reaction=== '3' ? 'Care' : item.reaction=== '4' ? 'HaHa' : item.reaction=== '5' ? 'Wow' : item.reaction=== '6' ? 'Sad' : 'Angry'}</span><span className="number">{Reaction.lenth === '' ? null : Reaction[num][0]['count']} </span></a>
                                                            }

                                                            <ul className="react-list">
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 1)}><img src="%PUBLIC_URL%/../assets/images/react/react-1.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 2)}><img src="%PUBLIC_URL%/../assets/images/react/react-2.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 3)}><img src="%PUBLIC_URL%/../assets/images/react/react-3.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 4)}><img src="%PUBLIC_URL%/../assets/images/react/react-4.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 5)}><img src="%PUBLIC_URL%/../assets/images/react/react-5.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 6)}><img src="%PUBLIC_URL%/../assets/images/react/react-6.png" alt="Like" /></a>
                                                                </li>
                                                                <li>
                                                                    <a onClick={() => AddReaction(item.id, 7)}><img src="%PUBLIC_URL%/../assets/images/react/react-7.png" alt="Like" /></a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="post-comment">
                                                            <a><i className="flaticon-comment" /><span>Comment</span> <span className="number">{comt.lenth === '' ? null : comt[num][0]['count']}</span></a>
                                                        </li>
                                                    </ul>
                                                    <form className="post-footer">
                                                        <div className="footer-image">
                                                            {
                                                                profile_data.other1 === '' ?
                                                                    <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                    :
                                                                    <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>

                                                            }
                                                        </div>
                                                        <table className="table">
                                                            <tbody><tr><td>
                                                                <div className="form-group">
                                                                    <textarea name="message" value={comments} onChange={(e) => setcomments(e.target.value)} className="form-control" placeholder="Write a comment..." />
                                                                </div>
                                                            </td>
                                                                <td>
                                                                    {
                                                                        comments === '' ?
                                                                            null
                                                                            :
                                                                            <div className="profile-btn">
                                                                                <a className="default-btn" onClick={() => AddComments(item.id)} ><i className="fa fa-paper-plane" /></a>
                                                                            </div>
                                                                    }
                                                                </td></tr>
                                                            </tbody>
                                                        </table>
                                                    </form>
                                                    <div className="post-comment-list">
                                                        {
                                                            studComments.map((c_data) =>
                                                                item.id == c_data.post_id ?
                                                                    <div className="comment-list">
                                                                        <div className="comment-image">
                                                                            {
                                                                                profile_data.other1 === '' ?
                                                                                <a href="/Profile"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle-self" alt="image" /></a>
                                                                                :
                                                                                <a href="/Profile"><img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle-self" alt="image" /></a>
                                                                            }
                                                                        </div>
                                                                        <div style={{float:"right"}}>
                                                                            {/* <a className="btn btn-sm btn-success" ><i className="fa fa-edit"></i></a> */}
                                                                            &nbsp;<a className="btn btn-sm btn-danger" onClick={() => delComments(c_data.id)}><i className="fa fa-trash"></i></a>
                                                                        </div>
                                                                        <div className="comment-info">
                                                                            <h3>{user && user.name} </h3>
                                                                            <div>
                                                                                <span>{Moment(c_data.created_at).format('DD/MM/YYYY HH:MM A')}</span>
                                                                                <p>{c_data.comments}</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr /></div>
                                                                    :
                                                                    null
                                                            )}
                                                    </div>
                                                </div>
                                            </div>

                                )}

                            <div className="load-more-posts-btn">
                                <a><i className="flaticon-loading" /> Load More Posts</a>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Content Page Box Area */}


        </div>
    )
}
