import React, { useEffect, useState } from 'react'
import '../.././App.css';
export default function Header() {
    let user = JSON.parse(localStorage.getItem('user_info'));
    const [profile_data, setData] = useState([]);

    const user_id = user['user_id'];
    const type = 'Student';
    useEffect(async () => {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);
    }, [])
    async function Logout() {
        const form_data = new FormData();
        form_data.append('user_id', user_id);
        form_data.append('type', type);
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/logout_details", {
            method: 'POST',
            body: form_data
        })
        result = await result.json();
        localStorage.clear();
        window.location.href = "/login";
    }
    return (
        <div>
            {/* Start Navbar Area */}
            <div className="navbar-area">
                <div className="main-responsive-nav">
                    <div className="main-responsive-menu">
                        <div className="responsive-burger-menu d-lg-none d-block">
                            <span className="top-bar" />
                            <span className="middle-bar" />
                            <span className="bottom-bar" />
                        </div>
                    </div>
                </div>
                <div className="main-navbar">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a href="/home" className="navbar-brand d-flex align-items-center">
                            <img className="logo-image" src="%PUBLIC_URL%/../assets/images/logo.png" alt="image" style={{ height: "40px" }} />
                        </a>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="search-box m-auto">
                                <form>
                                    <input type="text" className="input-search" placeholder="Search..." />
                                    <button type="submit"><i className="ri-search-line" /></button>
                                </form>
                            </div>

                            <div className="others-options d-flex align-items-center">
                            <div className="option-item">
                                    <a href="/home" className="home-btn" title="Home"><i className="flaticon-home" /></a>
                                </div>
                                <div className="option-item">
                                    <a href="/home" className="home-btn" title="News Feed"><i className="flaticon-newspaper" /></a>
                                </div>
                                <div className="option-item">
                                    <a href="/Profile" className="home-btn" title="Profile"><i className="flaticon-user" /></a>
                                </div>
                                <div className="option-item">
                                    <a href="/live-meeting" className="home-btn" title="Live Meeting"><i className="flaticon-youtube" /></a>
                                </div>
                                <div className="option-item">
                                    <div className="dropdown messages-nav-item">
                                        <a href="#" className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="messages-btn">
                                                <i className="flaticon-email" />
                                                <span>2</span>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="messages-header d-flex justify-content-between align-items-center">
                                                <h3>Messages</h3>
                                                <i className="flaticon-menu" />
                                            </div>
                                            <div className="messages-search-box">
                                                <form>
                                                    <input type="text" className="input-search" placeholder="Search Message..." />
                                                    <button type="submit"><i className="ri-search-line" /></button>
                                                </form>
                                            </div>
                                            <div className="messages-body" data-simplebar>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-11.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">James Vanwin</a></h4>
                                                        <span>Hello Dear I Want Talk To You</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-12.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Harry Lopez</a></h4>
                                                        <span>Hi. I Am looking For UI Designer</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-13.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Matthew Smith</a></h4>
                                                        <span>Thanks For Connecting!</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-14.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Russe Smith</a></h4>
                                                        <span>Thanks For Connecting!</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-1.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">James Vanwin</a></h4>
                                                        <span>Hello Dear I Want Talk To You</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-2.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Harry Lopez</a></h4>
                                                        <span>Hi. I Am looking For UI Designer</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-3.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Matthew Smith</a></h4>
                                                        <span>Thanks For Connecting!</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-4.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Russe Smith</a></h4>
                                                        <span>Thanks For Connecting!</span>
                                                    </div>
                                                </div>
                                                <div className="view-all-messages-btn">
                                                    <a href="messages.html" className="default-btn">View All Messages</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="option-item">
                                    <div className="dropdown notifications-nav-item">
                                        <a href="#" className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="notifications-btn">
                                                <i className="flaticon-bell" />
                                                <span>1</span>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="notifications-header d-flex justify-content-between align-items-center">
                                                <h3>Notifications</h3>
                                                <i className="flaticon-menu" />
                                            </div>
                                            <div className="notifications-body" data-simplebar>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-11.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">James Vanwin</a></h4>
                                                        <span>Posted A Comment On Your Status</span>
                                                        <span className="main-color">20 Minites Ago</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-12.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Harry Lopez</a></h4>
                                                        <span>Sent You A Friend Request</span>
                                                        <span className="main-color">2 Days Ago</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-13.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Matthew Smith</a></h4>
                                                        <span>Add A Photo In Design Group</span>
                                                        <span className="main-color">3 Days Ago</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-5.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">James Vanwin</a></h4>
                                                        <span>Posted A Comment On Your Status</span>
                                                        <span className="main-color">20 Minites Ago</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-6.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Harry Lopez</a></h4>
                                                        <span>Sent You A Friend Request</span>
                                                        <span className="main-color">2 Days Ago</span>
                                                    </div>
                                                </div>
                                                <div className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <a href="#"><img src="%PUBLIC_URL%/../assets/images/user/user-7.jpg" className="rounded-circle" alt="image" /></a>
                                                    </div>
                                                    <div className="text">
                                                        <h4><a href="#">Matthew Smith</a></h4>
                                                        <span>Add A Photo In Design Group</span>
                                                        <span className="main-color">3 Days Ago</span>
                                                    </div>
                                                </div>
                                                <div className="view-all-notifications-btn">
                                                    <a href="notifications.html" className="default-btn">View All Notifications</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="option-item">
                                    <div className="dropdown language-option">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="flaticon-global" />
                                            <span className="lang-name" />
                                        </button>
                                        <div className="dropdown-menu language-dropdown-menu">
                                            <a className="dropdown-item" href="#">
                                                <img src="%PUBLIC_URL%/../assets/images/uk.png" alt="flag" />
                                                Eng
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <img src="%PUBLIC_URL%/../assets/images/china.png" alt="flag" />
                                                Mar
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                <img src="%PUBLIC_URL%/../assets/images/uae.png" alt="flag" />
                                                العربيّة
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="option-item">
                                    <div className="dropdown profile-nav-item">
                                        <a href="#" className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {
                                                profile_data.other1 === '' ?
                                                    <div className="menu-profile">
                                                        <img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle" alt="image" />
                                                        <span className="name">{user && user.name}</span>
                                                        <span className="status-online" />
                                                    </div>
                                                    :
                                                    <div className="menu-profile">
                                                        <img src={'https://collegeblogbackend.digitaljayesh.com/' + profile_data.other1} className="rounded-circle" alt="image" />
                                                        <span className="name">{user && user.name}</span>
                                                        <span className="status-online" />
                                                    </div>
                                            }

                                        </a>
                                        <div className="dropdown-menu">
                                            <div className="profile-header">
                                                <h3>{user && user.name}</h3>
                                                <a href="mailto:{user && user.email}">{user && user.email}</a>
                                            </div>
                                            <ul className="profile-body">
                                                <li><i className="flaticon-user" /> <a href="/Profile">My Profile</a></li>
                                                <li><i className="flaticon-privacy" /> <a href="/privacy">Privacy</a></li>
                                                <li><i className="flaticon-information" /> <a href="/help">Help &amp; Support</a></li>
                                                <li><i className="flaticon-logout" /> <a href="#" onClick={Logout}>Logout</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </nav>
                </div>
            </div>
            {/* End Navbar Area */}

        </div>
    )
}
