import React, { useEffect, useState } from 'react'
import '../.././App.css';
import '../.././modal.css';
import Moment from 'moment';
import Swal from 'sweetalert2';

export default function Home() {

    let user = JSON.parse(localStorage.getItem('user_info'));
    const [postid, setpostid] = useState('');
    const [profile_data, setData] = useState([]);
    const [assigndata, setassigndata] = useState([]);
    const login_id = user['id'];
    const user_id = user['user_id'];
    const type = 'Student';

    const [inputFields, setinputFields] = useState([
        { assignfile: ''},
    ]);

    const handleChangeInput = (index, event) => {
        const values = [...inputFields];
        values[index][event.target.name] = event.target.value;
        setinputFields(values);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = [...inputFields];
        const form_data = new FormData();
        form_data.append('postid',postid);
        form_data.append('login_id', login_id);
        for (let i = 0; i < values.length; i++) 
        {
            form_data.append('assifile',inputFields[i]['assignfile']);
            console.log(inputFields[i]['assignfile']);
            console.log(login_id);
            let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/insert_completed_assignments ", { method: 'POST', body: form_data });
            result = await result.json();
            console.log(result);
            if (result==7777) {
                Swal.fire('Post Id Not Found', 'Please Try Again', 'error')
            }
            
                if (result === 1) {
                    Swal.fire('Success!', 'Assignements Successfully Added!', 'success')
                } else {
                    Swal.fire('Sorry!', 'Something was Wrong!', 'error')
                }
               
        }
        
        
    };

    const handleAddFields = () =>{
        setinputFields([...inputFields,{assignfile : ''}]);
    }

    const handleRemoveFields = (index) =>{
        const values = [...inputFields];
        values.splice(index,1);
        setinputFields(values);
        
    }

    useEffect(async () => {
        getAssignments();
    }, [])

    function setpostidData(postid) {
        setpostid(postid);
    }

    async function getAssignments() {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);
        const form_data = new FormData();
        form_data.append('login_id', login_id);
        form_data.append('clg_id', result.college_id);

        let result1 = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_stud_assignements ", { method: 'POST', body: form_data });
        result1 = await result1.json();
        setassigndata(result1);

    }


    return (
        <div>
            <div className="content-page-box-area">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="all-notifications-body">
                            <div className="all-notifications-header d-flex justify-content-between align-items-center">
                                <h3>Assignments</h3>
                            </div><hr />
                            {
                                assigndata.map((assignitem) =>
                                    <div className="item d-flex justify-content-between align-items-center" key={assignitem.id}>
                                        <div className="figure">
                                            {
                                                assignitem.teachimg === null ?
                                                    <a href="#"><img src="%PUBLIC_URL%/../assets/images/dummy.png" className="rounded-circle" alt="image" /></a>
                                                    :
                                                    <a href="#"><img src={'https://collegeblogbackend.digitaljayesh.com/' + assignitem.teachimg} className="rounded-circle" alt="image" /></a>
                                            }
                                        </div>
                                        <div className="text">
                                            <h4><a href="#">{assignitem.assign_title}</a></h4>
                                            <span>{assignitem.assign_descreption}</span>
                                            <span className="main-color">{
                                                Moment().diff(Moment(assignitem.created_at).format('YYYY-MM-DD HH:MM:SS'), 'hours') > 60 ?
                                                    <p> <i><b>Posted On : {Moment(assignitem.created_at).format('DD-MMM-YYYY')}</b></i></p>
                                                    :
                                                    <p> <i><b>Posted On :{Moment().diff(Moment(assignitem.created_at).format('YYYY-MM-DD HH:MM:SS'), 'hours')}  Hours ago </b></i></p>
                                            }</span>
                                        </div>
                                        <div className="icon">
                                            <a className="view" target="_blank" href={'https://collegeblogbackend.digitaljayesh.com/' + assignitem.assign_file} title="View Assignemnt File"><i className="fa fa-eye" /></a>
                                            &nbsp;<a className="check" href="#AsscompModal" onClick={() => setpostidData(assignitem.id)} title="Assignement Completed"><i className="fa fa-check" /></a>
                                        </div>
                                    </div>

                                )
                            }

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="all-notifications-body">
                            <div className="all-notifications-header d-flex justify-content-between align-items-center">
                                <h3>Completed Assignments</h3>
                            </div><hr />
                            <div className="item d-flex justify-content-between align-items-center">
                                <span>Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat.</span>
                                <div className="icon">
                                    <a href="#"><i className="fa fa-eye" /></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* ================================== Modals ==================================== */}
            <div id="AsscompModal" className="modal">
                <div className="modal__content">
                    <h3>Please Enter the files of Completed Assignments</h3>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-7">
                                {
                                    inputFields.map((inputFields, index) =>
                                        <div key={index} className="row">
                                            <div className="col-sm-12">
                                                <input type="file" className="form-control" name="assignfile" onChange={event => handleChangeInput(index, event)} value={inputFields.assignfile} />
                                            </div><br /><br />
                                        </div>
                                    )}
                            </div>
                            <div className="col-sm-5">
                                <a onClick={() => handleRemoveFields()} className="btn btn-sm  btn-danger" title=" Delete Row"><i className="fa fa-minus"></i></a>
                                &nbsp; <a onClick={() => handleAddFields()} className="btn btn-sm  btn-primary" title="Add Row"><i className="fa fa-plus"></i> </a>
                            </div>
                        </div><hr />
                        <div className="modal__footer">
                            <button onClick={handleSubmit} className="btn btn-success">Submit</button>
                        </div>
                    </form>

                    <a href="#" title="Close Modal" className="modal__close btn btn-danger btn-sm" >&times;</a>
                </div>
            </div>



        </div>
    )
}
