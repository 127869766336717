import React from 'react'

export default function Footer() {
    return (
        <div>
            {/* Start Copyright Area */}
            <div className="copyrights-area">
                <div className="container">
                    <div className="row align-items-center">
                        <p><i className="ri-copyright-line" /> 2021 Blog Posts All Rights Reserved. Share By <a href="https://digitalajyesh.com" target="_blank">Team Digital Jayesh</a></p>
                    </div>
                </div>
            </div>

            
            {/* End Copyright Area */}

        </div>
    )
}
