import React,{useEffect} from 'react';
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/pages/Header";
import Menu from "./components/Menu";
import PageNotFound from './components/PageNotFound';
import Protected from './components/Protected';
import Rsidebar from "./components/Rsidebar";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/pages/Home";
import Profile from "./components/pages/Profile";
import Editprofile from "./components/pages/Editprofile";
import Assignments from "./components/pages/Assignments";

function App() {
return (
    <div className="App">
        {
            localStorage.getItem('user_info') ?
            <> 
              <Header/>
              <Menu/>
                <BrowserRouter>
                  <Switch>
                    <Route exact path={["/home", "/#", "/"]}  ><Protected Cmp={Home}/></Route>
                    <Route exact path="/Profile"  ><Protected Cmp={Profile}/></Route>
                    <Route exact path="/edit-profile"  ><Protected Cmp={Editprofile}/></Route>
                    <Route exact path="/assignments"  ><Protected Cmp={Assignments}/></Route>
                    <Route component={PageNotFound} />
                  </Switch>
                </BrowserRouter>
              <Rsidebar/>
            <Footer/>
            </>   
            :
            <>
              <BrowserRouter>
                <Route path="/register"><Register/></Route>
                <Route exact path={["/", "/#", "/login"]}  ><Login/></Route>
              </BrowserRouter>
            </> 
      }
    </div>
  );
}

export default App;
