import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function Protected(props) {
    let Cmp = props.Cmp;
    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem('user_info')) {
            window.location.href = "/login";
        }
    },[])
    console.warn(Cmp);
    return (
        <div>
           <Cmp/>
        </div>
    )
}

export default Protected;