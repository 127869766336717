import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

export default function Login() {

    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [cpassword, setcpassword] = useState("")
    const [Clgid, setClg] = useState("")
    const [clg_data, setClgData] = useState([]);
    useEffect(async () => {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/colleges");
        result = await result.json();
        setClgData(result);
    }, [])

    async function RegisterStud() {
        if (password.length < 5) {
            Swal.fire('Password must be 5 charecter', '','error')
        }
        else{
            if (password===cpassword) 
            {
                var item = {name,email,password,Clgid};
            const form_data = new FormData();
                form_data.append('name', name);
                form_data.append('email', email);
                form_data.append('password', password);
                form_data.append('Clgid', Clgid);
        
                let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/insert_student", {
                    method: 'POST',
                    body: form_data
                })
                result = await result.json();
                console.log(result);
                if (result === 1) {
                    setname("");
                    setemail("");
                    setpassword("");
                    setcpassword("");
                    setClg("");
                    Swal.fire( 'Success!','Registration Successfully','success' );
                    window.location.href = "/login";
                } else if (result === 7){
                    Swal.fire('Something Was Wrong!','Your Username is Already Exist .','error' )
                }
            }
            else{
                Swal.fire('Password Not Match', '','error')
            }
        }
      }

    return (
        <div>
            <div className="" >
                <div className="container">
                    <div className="row" style={{ marginTop: "-70px" }}>
                        <div className="col-lg-6 col-md-12">
                            <div className="profile-authentication-image">
                                <div className="content-image">
                                    <div className="vector-image">
                                        <br /><br /><br /><br /><br />
                                        <a href="#"><img src="assets/images/logo.png" alt="Digital" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="login-form">
                                <h2>Register</h2>
                                <form > 
                                    <div className="form-group">
                                        <input required="true" list="listsss" id="list" className="form-control" placeholder="Select Your College"  onChange={(e) => setClg(e.target.value)}/>
                                        <datalist id="listsss">
                                            {
                                                clg_data.map((item) =>
                                                    <option key={item.id} value={item.id}>{item.college_name}</option>
                                            )}
                                        </datalist>

                                    </div>
                                    <div className="form-group">
                                        <input required="true" type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" placeholder="Name" />
                                    </div>
                                    <div className="form-group">
                                        <input required="true" type="email" value={email} onChange={(e) => setemail(e.target.value)} className="form-control" placeholder="Email or Username" />
                                    </div>
                                    <div className="form-group">
                                        <input required="true" type="password" value={password} onChange={(e) => setpassword(e.target.value)} className="form-control" placeholder="Password" />
                                    </div>
                                    <div className="form-group">
                                        <input required="true" type="password" value={cpassword} onChange={(e) => setcpassword(e.target.value)}  className="form-control" placeholder="Confirm Password" />
                                    </div>

                                    <button type="button" className="default-btn" onClick={RegisterStud}>Register</button>
                                    {/* <div className="or-text"><span>Or</span></div>
                                    <button type="submit" className="google-btn">Log In with Google</button> */}
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
