import React, { useEffect, useState } from 'react'
import '.././App.css';

export default function Rsidebar() {
    let user = JSON.parse(localStorage.getItem('user_info'));
    const [profile_data, setData] = useState([]);
    const [teacherdata1, setteacherdata] = useState([]);

    const user_id = user['user_id'];
    useEffect(async () => {
        let result = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_students/" + user_id);
        result = await result.json();
        setData(result);

        let result1 = await fetch("https://collegeblogbackend.digitaljayesh.com/api/get_teachers_data/" +  result.college_id);
        result1 = await result1.json();
        setteacherdata(result1);
    }, [])



    return (
        <div>
            {/* Start Right Sidebar Area */}
            <div className="right-sidebar-area" data-simplebar>
                <div className="recent-contact-box" style={{ marginTop: "-1px" }}>
                    <div className="title">
                        <h3>Recent  Chat</h3>
                    </div><hr/>
                    <div className="contact-body" data-simplebar>
                        {
                            teacherdata1.map((titem) =>
                                <div className="contact-item" key={titem.id}>
                                    <a href="#"><img src={'https://collegeblogbackend.digitaljayesh.com/' + titem.other1} className="rounded-circle" alt="image" /></a>
                                    <span className="name"><a href="#"> {titem.name}</a></span>
                                    <span  className="status-offline"/>
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button  className="btn btn-success btn-sm chat-badge"> 5</button> */}
                                </div>
                        )
                        }
                    </div>
                </div>
            </div>
            {/* End Right Sidebar Area */}


        </div>
    )
}
